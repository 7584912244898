import React from 'react'
// import { Link } from 'gatsby'
import Layout from '../layouts'
import './index.css'
import Kettle from '../images/kettle-creek.jpg'
import Pine from '../images/pine-creek.jpg'
import Flying from '../images/fyling-horse.jpg'
import CommonHero from '../components/commonhero/commonhero.js'

class IndexPage extends React.Component {
  render() {
    return (
      <Layout head={{ title: 'Northgate' }}>
        <CommonHero
          pageTitle="Northgate, Colorado Springs"
          custombackground="https://res.cloudinary.com/brian-boals/image/upload/v1561958820/brianboals/northgate-flying-horse.jpg"
        />
        <main className="bg-white p-relative">
          <div className="container py-5">
            <p>
              Northgate Colorado Springs is located on the northern parameter of
              Colorado Springs. This area is booming with entertainment,
              shopping, and some of the cities most desirable neighborhoods.
              Situated around the north gate for the United States Air Force
              Academy, this area boasts some of the best views of the entire
              front range.
            </p>
            <p>
              Desirable Academy District 20 serves this area along with the
              Rampart Campus of Pikes Peak Community College.
            </p>
          </div>
          <section className="py-5">
            <div className="container">
              <div className="intro text-center mb-5">
                <h2>Neighborhoods</h2>
              </div>
              <div className="row">
                <div className="col-sm-6 col-lg-4 p-1">
                  <div
                    className="area"
                    style={{
                      background: `url(${Flying})`,
                    }}
                  >
                    <div className="area-content">
                      <h4 className="text-white">
                        <a
                          href="https://brianboals-flyinghorse.flymycommunity.com/"
                          target="_blank"
                          className="text-white"
                          rel="noopener noreferrer"
                          title="Flying Horse"
                        >
                          Flying Horse
                        </a>
                      </h4>
                      <p>
                        Luxury community centered around award winning golf
                        course
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 p-1">
                  <div
                    className="area"
                    style={{
                      background: `url(${Pine})`,
                    }}
                  >
                    <div className="area-content">
                      <h4 className="text-white">
                        <a
                          href="https://brianboals-pinecreek.flymycommunity.com/"
                          target="_blank"
                          className="text-white"
                          rel="noopener noreferrer"
                          title="Pine Creek"
                        >
                          Pine Creek
                        </a>
                      </h4>
                      <p>
                        Pine Creek is situated at the north end of Colorado
                        Springs this high end neighborhood boasts outstanding
                        views and a gorgeous golf course.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 p-1">
                  <div
                    className="area"
                    style={{
                      background: `url(${Kettle})`,
                    }}
                  >
                    <div className="area-content">
                      <h4 className="text-white">
                        <a
                          href="https://brianboals-kettlecreek.flymycommunity.com/"
                          target="_blank"
                          className="text-white"
                          rel="noopener noreferrer"
                          title="Kettle Creek"
                        >
                          Kettle Creek
                        </a>
                      </h4>
                      <p>
                        Crystal Park is a private gated mountain community
                        within the Pikes Peak region. Located above Colorado
                        Springs and adjacent to Manitou Springs…
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default IndexPage
